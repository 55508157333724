<template>
    <v-dialog :value="value" width="450" persistent>
      <v-card>
        <v-card-title>
          Confirm Cancellation
        </v-card-title>
        <v-card-text>
          Cancel contract upload process for "{{ selectedContract.file_name }}"?
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="grey darken-1" text :disabled="loading" @click="close">
            Close
          </v-btn>
          <v-btn color="primary" :disabled="loading" :loading="loading" @click="cancel">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import ContractUpload from '@/axios/contract-upload-endpoint.js'
import { displayAlert } from '@/mixins/alert'
export default {
  data() {
    return {
      loading: false
    }
  },
  name: 'CancelDialog',
  mixins: [displayAlert],
  props: {
    value: Boolean,
    selectedContract: Object
  },
  methods: {
    close() {
      this.$emit('closeCancel', false)
    },
    async cancel () {
      this.loading = true
      try {
        await ContractUpload.cancelContractUpload(this.selectedContract.upload_id)
        this.$emit('closeCancel', true)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>